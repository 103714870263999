// @ts-check

/** @typedef {import('../../../infrastructure/common/shukka-system/gateway-resource/common/types').ShippingDocument} ShippingDocument */
/** @typedef {import('../../../infrastructure/common/shukka-system/gateway-resource/type.ts').InvoiceForMergedXlsx} InvoiceForMergedXlsx */
/** @typedef {import('./types').ValidationError} ValidationError */

const FORMULA_CELL_EMPTY_MSG =
  '※書類DL後、一度も編集・保存されていないファイルは差し替え書類としてアップロードできません';

/** 値が取得できないケースは「値が読み取れませんでした」のメッセージで統一 */
const CANNOT_READ_VALUE = '値が読み取れませんでした';

/**
 * スペース・改行を除去して値を取得する
 * @type {(value: string | number) => string | number}
 */
const clean = (value) => (typeof value === 'string' ? value.replace(/\s+/g, ' ') : value);

/** @type {(rows: (string | number)[][], indexes: [number, number]) => string | number} */
const getter = (rows, [row, col]) => {
  const value = rows.at(row)?.at(col);
  if (value === undefined) return CANNOT_READ_VALUE;
  if (typeof value === 'string') return clean(value);
  return value;
};

/** @type {(rows: (string|number)[][], doc: ShippingDocument) => ValidationError | null} */
export const invoiceValidator = (rows, doc) => {
  const title = '選択されたファイルは不正です';
  const fileType = getter(rows, [0, 0]);
  if (fileType !== 'Invoice')
    return {
      title,
      errors: [{ message: 'Invoice ファイルが選択されていません。' }],
    };

  /** @type {InvoiceForMergedXlsx} */
  const data = doc.values;

  /** @type {ValidationError['errors']} */
  const errors = [];

  // Invoice No のチェック
  const invoiceNo = getter(rows, [2, 8]);
  const invoiceNoInDoc = clean(data.matter_no);
  if (invoiceNo !== invoiceNoInDoc) {
    errors.push({
      message: 'Invoice No. に相違があります。',
      diff: { label: 'Invoice No.', doc: data.matter_no, file: String(invoiceNo) },
    });
  }

  // Total Q'ty のチェック
  const totalQty = getter(rows, [33 + data.invoice_details.length, 6]);
  const totalQtyInDoc = data.invoice_details.reduce((prev, current) => prev + current.quantity, 0);
  if (Number(totalQty) !== totalQtyInDoc) {
    errors.push({
      message: `Total Q'ty に相違があります。`,
      diff: { label: `Total Q'ty`, doc: String(totalQtyInDoc), file: String(totalQty) },
    });
  }

  // Total Price のチェック
  const totalPrice = getter(rows, [33 + data.invoice_details.length, 8]);
  const totalPriceRound = Math.round(Number(totalPrice) * 100) / 100;
  const totalPriceInDoc = data.sum_total_price;
  if (totalPriceRound !== totalPriceInDoc) {
    errors.push({
      message: 'Total Price に相違があります。',
      diff: { label: 'Total Price', doc: String(data.sum_total_price), file: String(totalPrice) },
    });
  }

  if (errors.length === 0) return null;
  const subtitle = [totalQty, totalPrice].every((v) => v === CANNOT_READ_VALUE) ? FORMULA_CELL_EMPTY_MSG : undefined;
  return { title, subtitle, errors };
};

/** @type {(rows: (string|number)[][], doc: ShippingDocument) => ValidationError} */
export const packingListValidator = (rows, doc) => {
  const title = '選択されたファイルは不正です';
  const fileType = getter(rows, [0, 0]);
  if (fileType !== 'Packing List (梱包明細書)')
    return {
      title,
      errors: [{ message: 'Packing List (梱包明細書) ファイルが選択されていません。' }],
    };

  /** @type {InvoiceForMergedXlsx} */
  const data = doc.values;

  /** @type {ValidationError['errors']} */
  const errors = [];

  // Invoice No のチェック
  const invoiceNo = getter(rows, [2, 5]);
  const invoiceNoInDoc = clean(data.matter_no);
  if (invoiceNo !== invoiceNoInDoc)
    errors.push({
      message: 'Invoice No. に相違があります。',
      diff: { label: 'Invoice No.', doc: data.matter_no, file: String(invoiceNo) },
    });

  // CARTONS (合計) のチェック
  const cartons = getter(rows, [10 + data.packings.length, 1]);
  const cartonsInDoc = data.packings.length;
  if (cartons !== cartonsInDoc)
    errors.push({
      message: `CARTONS (合計) に相違があります。`,
      diff: { label: `CARTONS (合計)`, doc: String(cartonsInDoc), file: String(cartons) },
    });

  if (errors.length === 0) return null;
  return { title, errors };
};

/** @type {(rows: (string|number)[][], doc: ShippingDocument) => ValidationError} */
export const shippingDetailsValidator = (rows, doc) => {
  const title = '選択されたファイルは不正です';
  /** @type {InvoiceForMergedXlsx} */
  const data = doc.values;
  const fileType = String(getter(rows, [0, 0])).toUpperCase();
  const fileTypeInDoc = String(`出荷明細 (${data.member_ship_to_courier_company})`).toUpperCase();
  if (fileType !== fileTypeInDoc)
    return {
      title,
      errors: [{ message: `${fileTypeInDoc} ファイルが選択されていません。` }],
    };

  /** @type {ValidationError['errors']} */
  const errors = [];

  // 案件No.(正式) のチェック
  const invoiceNo = getter(rows, [6, 1]);
  const invoiceNoInDoc = clean(data.matter_no);
  if (invoiceNo !== invoiceNoInDoc)
    errors.push({
      message: '案件No.(正式) に相違があります。',
      diff: { label: '案件No.(正式)', doc: data.matter_no, file: String(invoiceNo) },
    });

  // バイヤーID のチェック
  const buyerId = String(getter(rows, [8, 1]));
  const buyerIdInDoc = String(clean(data.member_no));
  if (buyerId !== buyerIdInDoc)
    errors.push({
      message: `バイヤーID に相違があります。`,
      diff: { label: `バイヤーID`, doc: buyerIdInDoc, file: buyerId },
    });

  if (errors.length === 0) return null;
  return { title, errors };
};
